import api from "../../common/api";
import { authHeader } from "./auth-header";


const getDashboard = () => {
  return api.get(process.env.REACT_APP_API_URL + `users/dashboard`, { 
    headers: authHeader(),

  },{ withCredentials: true });

};
const getReport = () => {
  return api.get(process.env.REACT_APP_API_URL + `admin/dashboard/report`, { 
    headers: authHeader(),

  },{ withCredentials: true });

};


/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  getDashboard,
  getReport
}