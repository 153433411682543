import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
// import MetisMen  u from "react-metismenu";
import { setEnableMobileMenu } from "../../../reducers/ThemeOptions";
import { AdminNav, Advisor, CSRNav } from "./NavItems";
import { Link, NavLink } from 'react-router-dom'
import withRouter from "../../../hooks/withRouter";
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';

function Nav(props) {
  const { enableMobileMenu, setEnableMobileMenu , authDetails = 1 } = props;

  const capability = (authDetails?.data);

  const [advisorNavItems, setAdvisorNavitems] = useState(Advisor);

  let parseCapability;
  if (capability != undefined) {
    parseCapability = JSON.parse(capability)

  }

  useEffect(() => {


    if (parseCapability?.add == false) {
      const newNavItems = [
        {
          icon: "pe-7s-graph2",
          label: "Dashboard",
          to: "/dashboard",
        },
        {
          icon: "pe-7s-user",
          label: "Client",
          submenu: [
            {
              label: "List",
              to: "/user/all",
            },
          ],
        },
      ];

      setAdvisorNavitems(newNavItems)

    }



  }, [capability]);
  const toggleMobileSidebar = () => {
    setEnableMobileMenu(!enableMobileMenu);
  };


  const [openSubMenu, setOpenSubMenu] = useState(null);

  const handleSubMenuClick = (label) => {
    setOpenSubMenu(openSubMenu === label ? null : label);
  };



  return (
    <>
      {/* <h5 className="app-sidebar__heading"></h5> */}
      {authDetails.roleId === 1 ? (

        <Sidebar >
          <Menu
            menuItemStyles={{
              button: {
                [`&.active`]: {
                  backgroundColor: '#545cd8',
                  color: '#fff',
                },
              },
            }}
          >
            {AdminNav.map((item, index) =>
              item.submenu ? (
                <SubMenu
                  key={index}
                  label={
                    <>
                      <i className={item.icon}></i> {item.label}
                    </>
                  }
                >
                  {item.submenu.map((subItem, subIndex) => (
                    <MenuItem
                      key={subIndex}
                      component={<NavLink to={subItem.to} />}
                      icon={<i className={subItem.icon}></i>}
                    >
                      {subItem.label}
                    </MenuItem>
                  ))}
                </SubMenu>
              ) : (
                <MenuItem
                  key={index}
                  component={<NavLink to={item.to} />}
                  icon={<i className={item.icon}></i>}
                >
                  {item.label}
                </MenuItem>
              )
            )}
          </Menu>
        </Sidebar>
      ) : (
        <Sidebar
        >
          <Menu
            menuItemStyles={{
              button: {
                [`&.active`]: {
                  backgroundColor: '#545cd8',
                  color: '#fff',
                },
              },
            }}
          >
            {advisorNavItems.map((item, index) =>
              item.submenu ? (
                <SubMenu
                  key={index}
                  label={
                    <>
                      <i className={item.icon}></i> {item.label}
                    </>
                  }
                  open={openSubMenu === item.label}
                  onClick={() => handleSubMenuClick(item.label)}
                >
                  {item.submenu.map((subItem, subIndex) => (
                    <MenuItem
                      key={subIndex}
                      component={<NavLink to={subItem.to} />}
                      icon={<i className={subItem.icon}></i>}
                    >
                      {subItem.label}
                    </MenuItem>
                  ))}
                </SubMenu>
              ) : (
                <MenuItem
                  key={index}
                  component={<NavLink to={item.to} />}
                  icon={<i className={item.icon}></i>}
                >
                  {item.label}
                </MenuItem>
              )
            )}
          </Menu>
        </Sidebar>
      )}
    </>
  );
}



const mapStateToProps = (state) => ({
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
});

const mapDispatchToProps = (dispatch) => ({
  setEnableMobileMenu: (enable) => dispatch(setEnableMobileMenu(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Nav));