/* eslint-disable import/no-anonymous-default-export */
import { authHeader } from "./auth-header";
import api from "../common/api";


const createSubscription = (data) => {
    return api.post(process.env.REACT_APP_API_URL + `subscription-plan`, data, {
        headers: authHeader(),
    },{withCredentials: true,});
};
const getSubscription = (data) => {
    return api.get(process.env.REACT_APP_API_URL + "subscription-plan", {
      headers: authHeader(),
      withCredentials: true,
      params: data,
    });
};
const getFeatures = (Isactive, planFor) => {
  return api.get(process.env.REACT_APP_API_URL + `features`, {
    headers: authHeader(),
    params: {
      Isactive,
      planFor,
    },
  },{withCredentials: true});
};

const deleteSubscription = (publicId, data) => {
  console.log(data)
  return api.delete(process.env.REACT_APP_API_URL + `subscription-plan/${publicId}`, {
    headers: authHeader(),
    data,
  },{withCredentials: true});
};

const restoreSubscription = (publicId,data) => {
  console.log(data)
  return api.get(process.env.REACT_APP_API_URL + `subscription-plan/restore/${publicId}`, {
    headers: authHeader(),
    params:      data,
    
  },{withCredentials: true});
};

const updateSubscription = (data) => {
  return api.put(process.env.REACT_APP_API_URL + `subscription-plan/${data.publicId}`, data, {
      headers: authHeader(),
  },{withCredentials: true,});
};
  
export default {
    createSubscription,
    getSubscription,
    getFeatures,
    deleteSubscription,
    restoreSubscription,
    updateSubscription
}