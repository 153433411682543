import {
  DELETE_SUBSCRIPTION,
  GET_FEATURES_LIST,
  GET_SUBSCRIPTION_LIST,
  RESTORE_SUBSCRIPTION,
  GET_SUBSCRIPTION_LIST_BY_ID
  } from "../actions/types";
  
const initialState = { subscription : [] , features:[], subscritptionList: [] ,count: 0};

const subscriptionReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_SUBSCRIPTION_LIST:
      return {
        ...state,
        subscription: payload.data.rows,
        count: payload.count
      };
      case GET_FEATURES_LIST:
      return {
        ...state,
        features: payload.data.rows
      }
    case DELETE_SUBSCRIPTION:
      return {
        ...state,
        subscription: payload.data.rows,
        count: payload.data.count
      };
    case RESTORE_SUBSCRIPTION:
      return {
        ...state,
        subscription: payload.data.rows,
        count: payload.data.count
      };
    case GET_SUBSCRIPTION_LIST_BY_ID:
      return {
        ...state,
        subscritptionList :payload.data.rows,
        count: payload.data.count
      }
    default:
        return state;
    }
  };
  
export default subscriptionReducer;