import {
    RETRIEVE_COUNTRIES,
  } from "../actions/types";
  
const initialState = [];

const countryReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {

    case RETRIEVE_COUNTRIES:
        return payload;

    default:
      return state;
  }
};

export default countryReducer;