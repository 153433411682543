import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Card, CardBody, Button, Row, Col } from "reactstrap";
import PageContainer from "../Layout/PageContainer";
import { ProcessFormula } from "../../redux/services/formula.service";
import Loader from "react-loaders";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

const ProcessFormulaPage = () => {
  const { id } = useParams();
  const [formulaData, setFormulaData] = useState(null);
  const [loading, setLoading] = useState(true); // Initially true to trigger API call
  console.log("publicId", id);

  useEffect(() => {
    if (id) {
      fetchFormulaData(id);
    }
  }, [id]);

  const fetchFormulaData = async (id) => {
    try {
      const response = await ProcessFormula({ id });

      if (!response || !response.data || !Array.isArray(response.data)) {
        throw new Error("Invalid response format");
      }
      // Check if data array is empty
      if (response.data.length === 0) {
        toast.error("No formula data available.", { autoClose: 3000 });
      } else {
        setFormulaData(response.data);
      }
    } catch (error) {
      console.error("Error fetching formula data:", error);
      toast.error("An error occurred while processing the formula.", {
        autoClose: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageContainer
      pageTitleIcon="pe-7s-magic-wand icon-gradient bg-plum-plate"
      pageHeading="Process Formula"
      pageSubTitle="View the formula rules and processed data"
    >
      <Row>
        {/* Formula Data Card */}
        {loading ? (
          <p className="text-center">Loading data...</p>
        ) : formulaData && formulaData.length > 0 ? (
          formulaData.map((user, index) => (
            <Card className="m-3" key={index}>
              <CardBody className="px-4">
                <h5 className="text-primary mb-3">
                  Processed Data #{index + 1}
                </h5>
                <div className="px-2">
                  <div className="row mb-2">
                    <div className="col-6">
                      <strong>Name:</strong> {user.firstName || "N/A"}
                    </div>
                    <div className="col-6">
                      <strong>Email:</strong> {user.email || "N/A"}
                    </div>
                  </div>

                  <div className="row mb-2">
                    <div className="col-6">
                      <strong>Phone Number:</strong> {user.phoneNumber || "N/A"}
                    </div>
                    <div className="col-6">
                      <strong>Location:</strong> {user.residenceCity || "N/A"}
                    </div>
                  </div>

                  <div className="row mb-2">
                    <div className="col-6">
                      <strong>Profile Completion:</strong>{" "}
                      {user.profileCompletionPercentage || "N/A"}%
                    </div>
                    <div className="col-6">
                      <strong>State Code:</strong> {user.stateCode || "N/A"}
                    </div>
                  </div>

                  <div className="row mb-2">
                    <div className="col-6">
                      <strong>Nationality:</strong> {user.nationality || "N/A"}
                    </div>
                    <div className="col-6">
                      <strong>Gender:</strong> {user.gender || "N/A"}
                    </div>
                  </div>

                  <div className="row mb-2">
                    <div className="col-6">
                      <strong>Degree Level:</strong> {user.degreeLevel || "N/A"}
                    </div>
                    <div className="col-6">
                      <strong>Date of Birth:</strong>{" "}
                      {user.dob
                        ? new Date(user.dob).toLocaleDateString()
                        : "N/A"}
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          ))
        ) : (
          <p className="text-center">No data available. Please try again.</p>
        )}
      </Row>
    </PageContainer>
  );
};

export default ProcessFormulaPage;
