import { GET_COMPANY_DETAIL ,GET_DOCUMENT,RETRIEVE_CURRENT_COMPANY, RETRIEVE_OPPORTUNITIES } from "../actions/types";

const initialData = { 
  company: {},
  companyDetails: {},
  opportunities: [],
  totalCount: 0,
};

const companyReducer = (state = initialData, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_COMPANY_DETAIL:
      return {
        ...state,
        company: payload,
      };
    case RETRIEVE_CURRENT_COMPANY:
      return {
        ...state , 
        companyDetails: payload
      };
      case GET_DOCUMENT:
      return {
        ...state,
        documents: payload,
      };
      case RETRIEVE_OPPORTUNITIES:
        return {
          ...state,
          opportunities: payload.rows,
          totalCount: payload.count,
        };
    default:
      return state;
  }
};

export default companyReducer;
