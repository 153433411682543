import {
    RETRIEVE_CITIES,
  } from "../actions/types";
  
const initialState = [];

const cityReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {

    case RETRIEVE_CITIES:
        return payload;

    default:
      return state;
  }
};

export default cityReducer;