import axios from "axios";
import { Navigate } from "react-router-dom";
import { store } from "../../config/configureStore";
import { clearSession } from "../actions/session";
import api from "../../common/api";

const login = (email, password, rememberMe) => {
  return axios
    .post(
      process.env.REACT_APP_API_URL + "user/signin",
      { email, password },
      {
        withCredentials: true,
      }
    )
    .then((response) => {
      return response.data;
    });
};

const logout = async () => {
  await api
    .get(process.env.REACT_APP_API_URL + "user/logout", {
      withCredentials: true,
    })
    .then((response) => {
      return response.data;
    });
  <Navigate to="/login" />;
  store.dispatch(clearSession());
};

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default { login, logout };
