export const AdminNav = [
  {
    icon: "pe-7s-graph2",
    label: "Dashboard",
    to: "/dashboard",
  },
];

export const Advisor = [
  {
    icon: "pe-7s-graph2 pe",
    label: "Dashboard",
    to: "/dashboard",
  },
  {
    icon: "pe-7s-user pe",
    label: "Talent",
    to: "/talent/list",
    // submenu: [
    //   {
    //     label: "Add",
    //     to: "/talent/add",
    //     icon: "pe-7s-plus",
    //   },
    //   {
    //     label: "List",
    //     to: "/talent/list",
    //     icon: "pe-7s-menu",
    //   },
    // ],
  },
  {
    icon: "pe-7s-network pe",
    label: "Company",
    to: "/company-user/list",
    // submenu: [
    //   {
    //     label: "Add",
    //     to: "/company-user/add",
    //     icon: "pe-7s-plus",
    //   },
    //   {
    //     label: "List",
    //     to: "/company-user/list",
    //     icon: "pe-7s-menu",
    //   },
    // ],
  },
  // {
  //   icon: "pe-7s-search",
  //   label: "Headhunter",
  //   submenu: [
  //     {
  //       label: "Add",
  //       to: "/headhunter/add",
  //       icon: "pe-7s-plus",
  //     },
  //     {
  //       label: "List",
  //       to: "/headhunter/list",
  //       icon: "pe-7s-menu",
  //     },
  //   ],
  // },
  {
    icon: "pe-7s-shuffle",
    label: "Subscription",
    submenu: [
      {
        label: "Add",
        to: "/subscription/add",
        icon: "pe-7s-plus",
      },
      {
        label: "List",
        to: "/subscription/list",
        icon: "pe-7s-menu",
      },
    ],
  },
  {
    icon: "pe-7s-news-paper",
    label: "CMS",
    submenu: [
      {
        label: "Add",
        to: "/cms/add",
        icon: "pe-7s-plus",
      },
      {
        label: "List",
        to: "/cms/list",
        icon: "pe-7s-menu",
      },
    ],
  },

  {
    icon: "pe-7s-graph3",
    label: "Formula",
    submenu: [
      {
        label: "Create",
        to: "/formula/add",
        icon: "pe-7s-plus",
      },
      {
        label: "List",
        to: "/formula/list",
        icon: "pe-7s-menu",
      },
    ],
  },

  {
    icon: "pe-7s-help1 pe",
    label: "Inquiry",
    to: "/inquiry",
  },
  {
    icon: "pe-7s-culture pe",
    label: "Activity",
    to: "/activity",
  },
  {
    icon: "pe-7s-config",
    label: "Settings",

    submenu: [
      {
        label: "Update",
        to: "/settings/config",
        icon: "pe-7s-plus",
      },
      {
        label: "List",
        to: "/settings/list",
        icon: "pe-7s-menu",
      },
    ],
  },
  // {
  //   icon: "pe-7s-headphones pe",
  //   label: "Contact Us",
  //   to: "/contact",
  // },
];
