import { CLEAR_SESSION, SET_USER_PERMISSION } from "../actions/types";

const initialState = {
  keys: {
    encryptedAESKey: "",
    userData: "",
    iv: "",
  },
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_USER_PERMISSION:
      return {
        ...state,
        keys: payload,
      };
    case CLEAR_SESSION:
      return {
        ...state,
        keys: {
          encryptedAESKey: "",
          userData: "",
          iv: "",
        },
      };
    default:
      return state;
  }
}
