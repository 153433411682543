import React from "react";
import { Table, Container, Row, Col } from "reactstrap";

const RuleTable = () => {
  const tableHeaders = ["Operator", "Description"];
  const tableData = [
    {
      operator: "=",
      description: "Strict equality (checks both value and type)",
      example: 'Q1 = "a" → Q1 equals "a"',
    },
    {
      operator: "!=",
      description: "Strict inequality (value and type must differ)",
      example: 'Q2 != "b" → Q2 is not "b"',
    },
    {
      operator: ">=",
      description: "Greater than or equal to",
      example: "Q3 >= 10 → Q3 is 10 or more",
    },
    {
      operator: "<=",
      description: "Less than or equal to",
      example: "Q4 <= 5 → Q4 is 5 or less",
    },
    {
      operator: ">",
      description: "Greater than",
      example: "Q3 > 15 → Q3 is strictly greater than 15",
    },
    {
      operator: "<",
      description: "Less than",
      example: "Q4 < 8 → Q4 is strictly less than 8",
    },
    {
      operator: "!=",
      description: "Inequality (loosely checks value)",
      example: 'Q1 != "b" → Q1 is not "b"',
    },
    {
      operator: "AND",
      description: "Logical AND (all conditions must be true)",
      example: 'Q1 === "a" AND Q2 === "b" → Both conditions must be true',
    },
    {
      operator: "OR",
      description: "Logical OR (at least one condition must be true)",
      example: 'Q1 === "a" OR Q3 !== "b" → At least one condition must be true',
    },
    {
      operator: "SUM",
      description:
        "Aggregation function to calculate the sum of the specified fields",
      example: "SUM(Q3, Q4) → Sum of values in fields Q3 and Q4",
    },
    {
      operator: "COUNT",
      description:
        "Aggregation function to count the number of entries in the specified fields",
      example: "COUNT(Q3, Q4) → Count of non-null entries in fields Q3 and Q4",
    },
    {
      operator: "AVG",
      description:
        "Aggregation function to calculate the average of the specified fields",
      example: "AVG(Q3, Q4) → Average of values in fields Q3 and Q4",
    },
    {
      operator: "MIN",
      description:
        "Aggregation function to find the minimum value among the specified fields",
      example: "MIN(Q3, Q4) → Minimum value between fields Q3 and Q4",
    },
    {
      operator: "MAX",
      description:
        "Aggregation function to find the maximum value among the specified fields",
      example: "MAX(Q3, Q4) → Maximum value between fields Q3 and Q4",
    },
    {
      operator: "IF-ELSE",
      description:
        "Conditional statement that executes one block of code if the condition is true and another if it is false.",
      example:
        "IF(Q1 > 100){ Q2 = 'Approved' } ELSE { Q2 = 'Rejected' } → If Q1 is greater than 100, Q2 is set to 'Approved', otherwise 'Rejected'.",
    },
  ];

  return (
    <Container>
      <Row>
        <Col>
          <h4 className="text-left">Rule Operators Reference</h4>
          <p className="text-left">
            Below is a detailed list of operators and their descriptions with
            examples.
          </p>
        </Col>
      </Row>
      <Row>
        <Col
          style={{
            maxHeight: "540px",
            overflowY: "auto",
            padding: "10px",
          }}
        >
          <Table bordered hover responsive>
            <thead className="table-dark">
              <tr>
                {tableHeaders.map((header, index) => (
                  <th key={index}>{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableData.map((row, index) => (
                <tr key={index}>
                  <td style={{ textAlign: "center" }}>
                    <code>{row.operator}</code>
                  </td>
                  <td>{row.description}</td>
                  {/* <td>{row.example}</td> */}
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export default RuleTable;
