import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SET_MESSAGE,
  LOGINUSER,
} from "./types";

import AuthService from "../services/auth.service";
import Cookies from "js-cookie";

export const login = (email, password, rememberMe) => (dispatch) => {
  return AuthService.login(email, password, rememberMe).then(
    (data) => {
      dispatch({
        type: LOGINUSER,
        payload: { user: data },
      });

      return Promise.resolve(data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: LOGIN_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const setUser = (user) => (dispatch) => {
  dispatch({
    type: LOGIN_SUCCESS,
    payload: user,
  });
};

export const logout = () => (dispatch) => {
  AuthService.logout();
  Cookies.remove("data", { path: "/" });
  Cookies.remove("AES_KEY", { path: "/" });
  Cookies.remove("IV", { path: "/" });
  dispatch({
    type: LOGOUT,
  });
};
