import { GET_TALENT_BY_ID } from "../actions/types";

const initialState = {
  talent: {},
};

const talentReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_TALENT_BY_ID:
      return {
        ...state,
        talent: payload,
      };

    default:
      return state;
  }
};

export default talentReducer;
