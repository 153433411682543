import {
  SHOW_IMAGE_BACKDROP,
  CLOSE_IMAGE_BACKDROP,
  SHOW_VIDEO_BACKDROP,
  CLOSE_VIDEO_BACKDROP,
} from "./types";

export const showImageBackdrop = (selectedImage) => (dispatch) => {
  dispatch({
    type: SHOW_IMAGE_BACKDROP,
    payload: selectedImage,
  });
};

export const closeImageBackdrop = () => (dispatch) => {
  dispatch({
    type: CLOSE_IMAGE_BACKDROP,
  });
};

export const showVideoBackdrop = (selectedVideo) => (dispatch) => {
  dispatch({
    type: SHOW_VIDEO_BACKDROP,
    payload: selectedVideo,
  });
};

export const closeVideoBackdrop = () => (dispatch) => {
  dispatch({
    type: CLOSE_VIDEO_BACKDROP,
  });
};
