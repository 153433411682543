import {
    RETRIEVE_QUERY,
   
  } from "../actions/types";
  
  const initialState = { query : [], totalQuery : 0 };

const queryReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {

    case RETRIEVE_QUERY:
      return {
        query: payload.rows,
        totalQuery: payload.count,
      };

        default:
      return state;
  }
};

export default queryReducer;