import ThemeOptions from "./ThemeOptions";
import { combineReducers } from "redux";
import auth from "./auth";
import message from "./message";
import users from "./users";
import opportunity from "./opportunity";
import roles from "./role";
import countries from "./countries";
import states from "./states";
import cities from "./cities";
import backdrops from "./backdrops";
import settings from "./settings";
import queryReducer from "./query";
import dashboardDataReducer from "./dashboard"
import userAfeReducer from "./ageUser";
import session from "./session";
// import company from './company'
import companyReducer from "./company";
import subscriptionReducer from './subscription'
import InquiryReducer from "./inquiry";
import pageReducer from "./pages";
const reducers = combineReducers({
  auth,
  message,
  users,
  roles,
  countries,
  states,
  cities,
  backdrops,
  settings,
  ThemeOptions,
  queryReducer,
  dashboardDataReducer,
  userAfeReducer,
  opportunity,
  session,
  companyReducer,
  subscriptionReducer,
  InquiryReducer,
  pageReducer
});

export default reducers;
