import React, { useState, useEffect, useMemo, useCallback } from "react";

import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Card, CardBody, Input } from "reactstrap";
import DataTable from "react-data-table-component";
import * as Ionicons from "react-icons/io";
import { toast  ,Slide} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import PageContainer from "../Layout/PageContainer";
import { deleteSubscription, restoreSubscription, retrieveSubscription } from "../../actions/subscription";

//import common filter component
import FilterComponent from "../../helpers/FilterComponent";
import debounceFunction from "../../helpers/Debounce";
import IconContainer from "../Common/IconContainer";
import subscriptionService from "../../services/subscription.service";
const DeleteIcon = Ionicons["IoIosTrash"];
const RestoreIcon = Ionicons["IoIosRefresh"];
const ViewIcon = Ionicons["IoIosEye"];
toast.configure();

const SubscriptionList = (props) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const {subscription:filterData} = useSelector((state) => state?.subscriptionReducer);

  const [perPage, setPerPage] = useState(10);

  const [currentPage, setCurrentPage] = useState(1);

  const [filterText, setFilterText] = useState("");

  useEffect(() => {
    const data = {
      keyword: filterText,
      page: currentPage,
      perPage,
      all: true,
    };
    dispatch(retrieveSubscription(data));
  }, [currentPage, dispatch, filterText, perPage]);

  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const debounceSearch = useCallback(
    debounceFunction(
      (nextValue) =>
        dispatch(retrieveSubscription({
          keyword: filterText,
          page: currentPage,
          nextValue,
          perPage,
          all: true,
          // active: true,
          // Isactive:1
        })),
      1000
    ),

    []
  );

  //subheader component of react-data-table
  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);

        setFilterText("");
        const data = {
          //   keyword: filterText,
          //   page: currentPage,
          //   perPage,
          //   excludeId: currentUser.id,
          // Isactive:1,
          all: true,
          //   active: true,
          };

        //dispatch to fetch users with role Id = 2 (csr)
        dispatch(retrieveSubscription(data));
      }
    };

    return (
    
          <FilterComponent
            onFilter={(event) => {
              setFilterText(event.target.value);
              debounceSearch(event.target.value);
            }}
            onClear={handleClear}
            filterText={filterText}
          />
     
    );
    
  }, [debounceSearch, dispatch, filterText, resetPaginationToggle]);

  //delete/restore handler
  const handleDelete = (e, id, action) => {

    e.preventDefault();

    

    if ( action === 'delete' ) {
      const data = {
        keyword: filterText,
        page: currentPage,
        perPage,
        all: true,
        // active: true,
        // Isactive:1
      };
        //dispatch to delete the user
        dispatch(deleteSubscription(id ,data))
        .then((res) => {

          toast("Subscription deleted successfully!", {
            transition: Slide,

            closeButton: true,

            autoClose: 3000,

            position: "top-right",

            type: "success", // info/success/warning/error
          });
        })
        .catch((error) => {
          console.log(error)
          toast(error.response.data.message, {
            transition: Slide,

            closeButton: true,

            autoClose: 3000,

            position: "top-right",

            type: "error",
          });
        });

    } else {
      const data = {
        keyword: filterText,
        page: currentPage,
        perPage,
        all: true,
        // active: true
      };
        // dispatch to restore the user
        dispatch(restoreSubscription(id ,data))
        .then((response) => {

          toast('Subscription restore successfully!', {
            transition: Slide,

            closeButton: true,

            autoClose: 3000,

            position: "top-right",

            type: "success", // info/success/warning/error
          });
        })
        .catch((error) => {
          console.log(error)
          toast(error.response.data.message, {
            transition: Slide,

            closeButton: true,

            autoClose: 3000,

            position: "top-right",

            type: "error",
          });
        });

    }

  };

  const handleStatusChange = (e, id, status) => {
    e.preventDefault();

    // const data = {
    //   roleId: "3",
    //   isActive: status,
    //   keyword: filterText,
    //   page: currentPage,
    //   perPage,
    //   excludeId: currentUser.id,
    //   all: true,
    //   active: true
    // };
    const data = {
      publicId: id,
      keyword: filterText,
      page: currentPage,
      perPage,
      isActive: status,
      all: true,
    };

    let message = '';
    
    if ( status === "0" ) {
      
      message = "Subscription deactivated successfully!";
      
    } else if( status === "1" ) {
      
      message = "Subscription activated successfully!";

    } else {

      message = "Subscription suspended successfully!";

    }
    subscriptionService.updateSubscription(data)

    //dispatch to update the status of the user
    // dispatch(updateUserStatus(id, data))
      .then((response) => {

        toast(message, {
          transition: Slide,

          closeButton: true,

          autoClose: 3000,

          position: "top-right",

          type: "success", // info/success/warning/error
        });
      })
      .catch((error) => {
        toast(error.response.data.message, {
          transition: Slide,

          closeButton: true,

          autoClose: 3000,

          position: "top-right",

          type: "error",
        });
      });
  };

  const columns = useMemo(
    () => [
      {
        name: "Plan Type",
        selector: (row) => row.planFor === 0 ? 'Talent' : ''||  row.planFor === 1 ? 'Company' : ''  || "--",
        sortable: true,
        headerStyle: { textAlign: "center" },
      },
      {
        name: "Period",
        selector: (row) =>  row.frequency === 1 ? 'Monthly Plan' : '' 
         || row.frequency === 2 ? 'Yearly Plan' : ''
         || "--",
        sortable: true,
        headerStyle: { textAlign: "center" },
      },
      {
        name: "Price",
        selector: (row) => `$${row.price}`  || "--",
        sortable: true,
        headerStyle: { textAlign: "center" },
      },
      {
        name: "Plan Title",
        selector: (row) => row.title || "--",
        sortable: true,
        headerStyle: { textAlign: "center" },
      },
      {
        name: "Actions",
        button: true,
        minWidth: "218px",
        headerStyle: { textAlign: "center" },
        cell: (row) => (
          <>
           {row.deletedAt === null && (
              <IconContainer
                Icon={ViewIcon}
                handleOnClick={() => handleEditClick(row)}
                text="Edit"
              />
            )}
          <div>
            <DeleteIcon id="delete-icon" style={{ display: "none"}}/>
            <RestoreIcon id="restore-icon" style={{ display: "none"}}/>
          </div>
            <IconContainer
              id={row.deletedAt === null ? 'delete-icon' : 'restore-icon'}
              Icon={row.deletedAt === null ? DeleteIcon : RestoreIcon}
              handleOnClick={(e) =>
                row.deletedAt === null
                  ? handleDelete(e, row.publicId, 'delete')
                  : handleDelete(e, row.publicId, 'restore')
              }
              text={row.deletedAt === null ? 'Delete' : 'Restore'}
              iconColor={row.deletedAt === null ? '#d92550' : '#3ac47d'}
            />

            { row.deletedAt === null &&
              <Input
                type="select"
                defaultValue={row.isActive}
                id="isActive"
                name="isActive"
                onChange={(e) => handleStatusChange(e, row.publicId, e.target.value)}
                style={{ marginLeft: "10px", width: "50%" }}
              >
                <option value="0">Inactive</option>
                <option value="1">Active</option>
              </Input>
            }
          </>
        ),
      },
    ],
    []
  );

  const handleEditClick = (row) => navigate(`/subscription/edit/${row.publicId}`);

  
  const handlePageChange = (page) => {
    //dispatch to fetch users with role Id = 2 (csr)
    const data = {
      keyword: filterText,
      page: currentPage,
      perPage,
      all: true,
      // active: true,
      // Isactive:1
    };
    dispatch(retrieveSubscription(data));

    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    const data = {
      keyword: filterText,
      page: currentPage,
      perPage,
      all: true,
      // active: true,
      // Isactive:1
      // excludeId: currentUser.id,
    };
    dispatch(retrieveSubscription(data));
    // dispatch(retrieveSubscription(1, filterText, page, newPerPage, currentUser.id, true, true));

    setPerPage(newPerPage);
  };

  return (
    <PageContainer
      pageTitleIcon="pe-7s-chat icon-gradient bg-plum-plate"
      pageHeading="Subscription List"
      pageSubTitle="Listing Subscription on the system"
    >
      <Row>
        <Col md="12">
          <Card className="main-card mb-3">
            <CardBody>
              <DataTable
                columns={columns}
                data={filterData}
                pagination
                // paginationPerPage = '25'
                paginationServer
                paginationTotalRows={"10"}
                paginationDefaultPage={currentPage}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                subHeader
                subHeaderComponent={subHeaderComponent}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </PageContainer>
  );
};

export default SubscriptionList