import {
    RETRIEVE_USERS_AGE,
  } from "../actions/types";
  
  const initialState = { usersAge : [], totalUserAgeCount : 0 };

const userAfeReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {

    case RETRIEVE_USERS_AGE:
      return {
        usersAge: payload.rows,
        totalUserAgeCount: payload.count,
      };


    default:
      return state;
  }
};

export default userAfeReducer;