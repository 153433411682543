import { GET_CLIENT_ADVISOR, GET_REPORT } from "../actions/types";

const initialState = {
  clinetsData: null,
  advisorData: null,
  queryData: null,
  stepsCompletedData: null,
  stepsInprogressData: null,
  momentData: null,
  futureData: null,
  stabilityData: null,
  opportunityData: null,
  reportData: null,
};

const dashboardDataReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_CLIENT_ADVISOR:
      return {
        ...state,
        talentsData: payload?.talentCount,
        companyData: payload?.companyCount,
        headhunterData: payload?.headhunterCount,
        opportunityData: payload?.opportunitiesCount,
        subscriptionData: payload?.subscriptionCount,
      };

    case GET_REPORT:
      return {
        ...state,
        reportData: payload,
      };

    default:
      return state;
  }
};

export default dashboardDataReducer;
