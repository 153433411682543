import subscriptionService from "../services/subscription.service";
import { GET_SUBSCRIPTION_LIST ,GET_FEATURES_LIST ,DELETE_SUBSCRIPTION, RESTORE_SUBSCRIPTION , GET_SUBSCRIPTION_LIST_BY_ID} from "./types"; 

export const retrieveSubscription = (data) => async (dispatch) => {
  try {
    const res = await subscriptionService.getSubscription(data);
    dispatch({
      type: GET_SUBSCRIPTION_LIST,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const retrieveSubscriptionById = (data) => async (dispatch) => {
  try {
    // console.log(data)
    const res = await subscriptionService.getSubscription(data);
    dispatch({
      type: GET_SUBSCRIPTION_LIST_BY_ID,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};


export const getFeatures = (isActive , planFor) => async (dispatch) => {
  try {
    const res = await subscriptionService.getFeatures(isActive, planFor);
    console.log(res.data)
    dispatch({
      type: GET_FEATURES_LIST,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};



export const deleteSubscription = (id, data) => async (dispatch) => {
  try {
    const res = await subscriptionService.deleteSubscription(id, data);
    dispatch({
      type: DELETE_SUBSCRIPTION,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const restoreSubscription = (id, data) => async (dispatch) => {
  try {
    const res = await subscriptionService.restoreSubscription(id, data);

    dispatch({
      type: RESTORE_SUBSCRIPTION,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};