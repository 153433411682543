import React from "react";

import { CSSTransition, TransitionGroup } from 'react-transition-group';

import HeaderLogo from "../AppLogo";
import { Input } from "reactstrap";
import UserBox from "./UserBox";

const Header = (props) => {

  const [language, setLanguage] = React.useState('');

  const handleLang = (e) => {
    // setLanguage(e.target.value);
    // const html = document.getElementsByTagName('html')[0]
    // console.log(html ,language)
    // if(language === 'ar-SA') {
    //   html.setAttribute('dir' , 'rtl')
    // }else if (language === 'en-US'){
    //   html.setAttribute('dir' , 'ltr')
    // }
    // You can add additional logic here if needed
  };
  // const handleLang = (e) => {
  // }

    const authDetails= props.authDetails;
  // console.log(authDetails)
    return (
      <>
        <TransitionGroup>
          <CSSTransition component="div"
            className="app-header header-shadow"
            appear={true} timeout={1500} enter={false} exit={false}>
            <div>
              <HeaderLogo />
              <div className="app-header__content">
                <div className="app-header-right">
                  <div>
                  {/* <Input
                        type="select"
                        name="planFor"
                        id="planFor"
                        value={language}
                        onChange={(e) => handleLang(e)} 
                      > */}
                        {/* <option value=" ">Select Plan Type</option> 
                        <option value="Company">Company Plan</option>
                        <option value="Talent">Talent Plan</option> */}
                    {/* <option value='en-US'>en-US</option>
                    <option value='ar-SA'>ar-SA</option>
                      </Input> */}
                  {/* <select onChange={handleLang}>
                </select> */}
                  </div>
                  <UserBox authDetails={authDetails} />
                </div>
              </div>
            </div>
          </CSSTransition>
        </TransitionGroup>
      </>
    );
}

export default Header;
