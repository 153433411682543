import {
    UPDATE_SETTINGS,
  } from "../actions/types";
  
  const initialState = { settings : [] };

const settingReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {

    case UPDATE_SETTINGS:
      return payload;

    default:
      return state;
  }
};

export default settingReducer;