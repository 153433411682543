import {
    RETRIEVE_STEPS,
    RETRIEVE_STEP_TWO,
    RETRIEVE_STEP_THREE,
    UPDATE_STEPS,
    RETRIEVE_STEP_FOUR,
    RETRIEVE_STEP_FIVE,
    RETRIEVE_STEP_SIX,
    RETRIEVE_STEP_SEVEN,
    RETRIVE_BALANCE_SHEET


} from "../actions/types";

const initialState = {
    

    stepOne : null,

    stepTwo : null,

    stepThree: null,

    balanceSheet :  null,

    stepFour: {

        stepFour32Percent : null,
        stepFour34PercentData : {
            income : null,
            saving : null,
            livingExp : null,
            taxesAndloan : null,
            potentialAdjustment : null,
            fiveUseOfmoney : null
        }
        
    },


    stepFive: [{
        goalTitle: "",
        goalStatus: "",
        goalDescr: ""
    }],

    stepSix: null,
    stepSeven: {
        questions: {
            updatedWill: "",
            insurance: "",
            titledProperly: "",
            healthInsurance: "",
            maximizing: ""
        },

        financialContacts: [
            {
                firstName: "",
                lastName: "",
                phoneNumber: "",
                email: "",
                profession: "",
                companyName: ""
            }

        ]
    }
};
const stepsReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case RETRIEVE_STEPS:
            return { ...state, stepOne: payload };

        case RETRIEVE_STEP_TWO:
            return { ...state, stepTwo: payload };

        case RETRIEVE_STEP_THREE:
            return { ...state, stepThree: payload };

        case RETRIEVE_STEP_FOUR:
            return { ...state, stepFour: payload };

        case RETRIEVE_STEP_FIVE:
            return { ...state, stepFive: payload };

        case RETRIEVE_STEP_SIX:
            return { ...state, stepSix: payload };

        case RETRIEVE_STEP_SEVEN:
            return { ...state, stepSeven: payload };

        case RETRIVE_BALANCE_SHEET:
            return { ...state, balanceSheet: payload };

        case UPDATE_STEPS:
            return state;

        default:
            return state;
    }
};

export default stepsReducer;
