import { RETRIEVE_INQUIRY  ,RETRIEVE_INQUIRY_DETAILS} from "../actions/types";
  
const initialState = { inquiry : [], details:[]};

const InquiryReducer = (state = initialState, action) => {

  const { type, payload } = action;

  switch (type) {

    case RETRIEVE_INQUIRY:
      return {
        ...state,
        inquiry: payload,
    };
    case RETRIEVE_INQUIRY_DETAILS:
        return {
          ...state,
          details: payload,
      };
  
    default:
      return state;
  }
};

export default InquiryReducer;