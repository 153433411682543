import {
    RETRIEVE_USERS_NETWORTH,
  } from "../actions/types";
  
  const initialState = { usersNetWorth : [], totalUserNetWorthCount : 0 };

const userNetWorthReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {

    case RETRIEVE_USERS_NETWORTH:
      return {
        usersNetWorth: payload.rows,
        totalUserNetWorthCount: payload.count,
      };


    default:
      return state;
  }
};

export default userNetWorthReducer;