'use strict'
import axois from 'axios';
import { logout } from "../actions/auth"; // Assuming you have a logout action
import { store } from "../config/configureStore";
import {decrypt} from "../utils/decrypt";
import { useDispatch, useSelector } from 'react-redux';
// import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";

// debugger
const api = axois.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials:true
})


const getRefreshToken = async (userId) => {
    try {
        const res = api.get( process.env.REACT_APP_API_URL + `user/renewSession/${userId}` ,  {withCredentials:true})
        if (!res) return;
        // console.log(res)
        if (res) {
          return res
        } else {
            store.dispatch(logout());
        }
    }catch(error){
        console.log(error)
        store.dispatch(logout());
    }
}

// const dispatch = useDispatch()
api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    //403 
    //401 = JWT Expire
    // console.log(error.response.status)
    if (error.response && error.response.status === 401) {
      const state = store.getState();
      const userId = state.auth?.user?.publicId; 
      // console.log(userId)
      getRefreshToken(userId).then(async (res) => {
        // console.log(res)
        if (res.status === 200) {
          await decrypt(res.data.response).then((res) => {
            // console.log(res)
            // debugger
            if(res) {
              // const navigate = useNavigate();

              // window.location.reload();
              // window.location.href('/dashboard')
              // history.replace("/same-page");
              // navigate("/dashboard");

            }else {
              store.dispatch(logout());
            }
            // console.log(res)
            // dispatch({
            //   type: LOGINUSER,
            //   payload: { user: data },
            // });
          });
        } else {
          store.dispatch(logout());
        }
      });
    }
    return Promise.reject(error);
  }
);

export default api;