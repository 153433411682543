import { CLEAR_SESSION, SET_USER_PERMISSION } from "./types";

export const setSession = (keys) => ({
  type: SET_USER_PERMISSION,
  payload: keys,
});

export const clearSession = () => ({
  type: CLEAR_SESSION,
  payload: null,
});
