import api from "../../common/api";
import { authHeader } from "./auth-header";

export const Getallmasterfeature = () => {
  return api
    .get(process.env.REACT_APP_API_URL + "admin/master", {
      headers: authHeader(),
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};
export const Createformula = (data) => {
  return api
    .post(process.env.REACT_APP_API_URL + "admin/formula", data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};
export const Getallcategories = () => {
  return api
    .get(process.env.REACT_APP_API_URL + "admin/categories", {
      headers: authHeader(),
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};
export const Getallsubcategories = (id) => {
  return api
    .get(process.env.REACT_APP_API_URL + `admin/subcategories/${id}`, {
      headers: authHeader(),
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};
export const Getformulalist = (
  params = { page: 1, perPage: 10, keyword: "" }
) => {
  return api
    .get(process.env.REACT_APP_API_URL + "admin/formulas", {
      headers: authHeader(),
      params,
    })

    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};
export const Getformuladetails = (id) => {
  return api
    .get(process.env.REACT_APP_API_URL + `admin/formula/${id}`, {
      headers: authHeader(),
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const Getduplicate = (id) => {
  return api
    .get(process.env.REACT_APP_API_URL + `admin/formula/duplicate/${id}`, {
      headers: authHeader(),
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};
export const Getdelete = (id) => {
  return api
    .delete(process.env.REACT_APP_API_URL + `admin/formula/${id}`, {
      headers: authHeader(),
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const changetogglestatus = (id) => {
  return api.put(process.env.REACT_APP_API_URL + `admin/status/${id}`, {
    headers: authHeader(),
  });
};

export const ChangeDefaultStatus = (id) => {
  return api
    .put(process.env.REACT_APP_API_URL + `admin/formula/default/${id}`, {
      headers: authHeader(),
    })
    .then((response) => {
      console.log("Formula status updated:", response.data);
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const Updateformula = (payload, id) => {
  return api
    .put(process.env.REACT_APP_API_URL + `admin/formula/${id}`, payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const UpdatePriority = (payload, id) => {
  return api
    .put(
      process.env.REACT_APP_API_URL + `admin/formula/priority/${id}`,
      payload
    )
    .then((response) => {
      console.log("UpdatePriority", response);
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const ExportOpportunity = (from, to) => {
  const apiUrl = `${process.env.REACT_APP_API_URL}admin/formulas/export?from=${from}&to=${to}`;

  return api
    .get(apiUrl, {
      headers: authHeader(),
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("ExportOpportunity Error:", error);
      return {
        error: error.response?.data?.message || "Failed to download report",
      };
    });
};

export const Deleteequation = (id) => {
  return api
    .delete(process.env.REACT_APP_API_URL + `admin/formula/equation/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};
export const GetallOperators = () => {
  return api
    .get(process.env.REACT_APP_API_URL + "admin/operators", {
      headers: authHeader(),
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};
export const GetFormulaSearch = (keyword) => {
  const headers = authHeader();
  return api
    .get(
      `${process.env.REACT_APP_API_URL}/getFormulaSearch?keyword=${keyword}`,
      { headers }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
};

export const ProcessFormula = (data) => {
  return api
    .post(process.env.REACT_APP_API_URL + "processformula", data)
    .then((response) => {
      console.log("ProcessFormula", response);
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const verifyFormula = (data) => {
  return api
    .post(process.env.REACT_APP_API_URL + "executeEquation", data)
    .then((response) => {
      console.log("executeEquation", response);
      return response;
    })
    .catch((error) => {
      return error;
    });
};
