import {
    UPDATE_BECOME_A_HOST,
  } from "../actions/types";
  
  const initialState = { settings : [] };

const becomeAHostReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {

    case UPDATE_BECOME_A_HOST:
      return payload;

    default:
      return state;
  }
};

export default becomeAHostReducer;