import { authHeader, multipartHeader } from "./auth-header";
import api from "../common/api";
const getAll = (roleId, keyword, page, perPage, excludeId, filterUser, all, active, isStepCompleted, isExistingUser) => {
  return api.get(process.env.REACT_APP_API_URL + "talents/list", {
    headers: authHeader(),
    withCredentials: true,
    data: {},
    params: {
      roleId: roleId,
      keyword: keyword,
      page: page,
      perPage: perPage,
      excludeId: excludeId,
      filterUser,
      all,
      active,
      isStepCompleted,
      isExistingUser
    },
  });
};

// get age users
const getAllAge = (roleId, keyword, page, perPage, excludeId, filterUser, all, active, isStepCompleted, isExistingUser, age) => {
  return api.get(process.env.REACT_APP_API_URL + "users/listFilter", {
    headers: authHeader(),
    withCredentials: true,
    data: {},
    params: {
      roleId: roleId,
      keyword: keyword,
      page: page,
      perPage: perPage,
      excludeId: excludeId,
      filterUser,
      all,
      active,
      isStepCompleted,
      isExistingUser,
      age
    },
  });
};

// get risk users
const getAllRisk = (roleId, keyword, page, perPage, excludeId, filterUser, all, active, isStepCompleted, isExistingUser, risk) => {
  return api.get(process.env.REACT_APP_API_URL + "users/listFilterRisk", {
    headers: authHeader(),
    withCredentials: true,
    data: {},
    params: {
      roleId: roleId,
      keyword: keyword,
      page: page,
      perPage: perPage,
      excludeId: excludeId,
      filterUser,
      all,
      active,
      isStepCompleted,
      isExistingUser,
      risk
    },
  });
};

const getAllNetWorth = (roleId, keyword, page, perPage, excludeId, filterUser, all, active, isStepCompleted, isExistingUser, netWorth) => {
  return api.get(process.env.REACT_APP_API_URL + "users/listFilterNetWorth", {
    headers: authHeader(),
    data: {},
    params: {
      roleId: roleId,
      keyword: keyword,
      page: page,
      perPage: perPage,
      excludeId: excludeId,
      filterUser,
      all,
      active,
      isStepCompleted,
      isExistingUser,
      netWorth
    },
  },{withCredentials: true,});
};



const getAllAdvisors = (roleId, keyword, page, perPage, excludeId, filterUser, all, active) => {
  return api.get(process.env.REACT_APP_API_URL + "users/list", {
    headers: authHeader(),
    data: {},
    params: {
      roleId: roleId,
      keyword: keyword,
      page: page,
      perPage: perPage,
      excludeId: excludeId,
      filterUser,
      all,
      active
    },
  },{withCredentials: true,});
};

const get = (id) => {
  return api.get(process.env.REACT_APP_API_URL + `user/view`, {
    headers: authHeader(),
    data: {},
    params: {
      userId: id,
    },
  },{withCredentials: true});
};

const create = (data) => {
  return api.post(process.env.REACT_APP_API_URL + `user/create`, data, {
    headers: multipartHeader(),
  },{withCredentials: true,});
};

const update = (id, data) => {
  return api.put(process.env.REACT_APP_API_URL + `user/update/${id}`, data, {
    headers: multipartHeader(),
  },{withCredentials: true,});
};

// update risk score 
const updateRiskScore = (data,id) => {

  return api.post(process.env.REACT_APP_API_URL + `questions/updateUserRiskScore/${id}`, data, {
    headers: authHeader(),
  },{withCredentials: true,});

};

const updateStatus = (id, data) => {
  return api.put(
    process.env.REACT_APP_API_URL + `user/updateStatus/${id}`, data,
    {
      headers: authHeader(),
    }
  );
};

const verifyUserTaxInfo = (id, data) => {
  return api.put(
    process.env.REACT_APP_API_URL + `user/verifyUserTaxInfo/${id}`,
    data,
    {
      headers: authHeader(),
    }
  );
};

const deleteUser = (id, data) => {
  return api.delete(process.env.REACT_APP_API_URL + `user/delete/${id}`, {
    headers: authHeader(),
    data,
  });
};

const restore = (id, data) => {
  return api.get(process.env.REACT_APP_API_URL + `user/restore/${id}`, {
    headers: authHeader(),
    params: data
  });
};


const getCustomerQuery = (filterText, page, perPage) => {
  return api.get(process.env.REACT_APP_API_URL + `getContactUsList`, {
    headers: authHeader(),

    params: {
      keyword: filterText,
      page: page,
      perPage: perPage,
    },

  })
}


const deleteCustomerQuery = (id) => {

  const data = {id : id}

  return api.post(process.env.REACT_APP_API_URL + `deleteContactUs`, data, {
    headers: authHeader(),
  })

}

const getParticularQuery = (id) => {

  return api.get(process.env.REACT_APP_API_URL + `getContactUs/${id}`, {
    headers: authHeader(),
   
  });

}


// refresh hubspot advisor

const refreshHubspotAdvisor = () => {

  return api.post(process.env.REACT_APP_API_URL + `createAdvisor`, {
   
  });

}






export default {
  getAll,
  get,
  create,
  update,
  updateStatus,
  verifyUserTaxInfo,
  deleteUser,
  restore,
  getAllAdvisors,
  getCustomerQuery,
  deleteCustomerQuery,
  getParticularQuery,
  getAllAge,
  getAllRisk,
  getAllNetWorth,
  refreshHubspotAdvisor,
  updateRiskScore,
  // getCompanyDetails
};
