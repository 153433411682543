import { Routes, Route, useLocation } from "react-router-dom";
import React, { useEffect, lazy } from "react";
import { useDispatch, useSelector } from "react-redux";

import { clearMessage } from "../../../actions/message";
import { decrypt } from "../../../utils/decrypt";
import ProtectedRoutes from "../../../routes/ProtectedRoutes";
import PublicRoutes from "../../../routes/PublicRoutes";
import { clearSession } from "../../../actions/session";
import PlansListDetails from "../../Plans/PlansListDetails";
import SubscriptionAdd from "../../Subscription/SubscriptionAdd";
import SubscriptionList from "../../Subscription/SubscriptionList";
import NotFound from "../../Notfound/NotFound";
import SubscriptionEdit from "../../Subscription/SubscriptionEdit";
// import NotFound from "../../404/404";
const Login = lazy(() => import("../../Login/"));
const Dashboard = lazy(() => import("../../Dashboard/"));
const ForgotPassword = lazy(() => import("../../ForgotPassword/"));
const ResetPassword = lazy(() => import("../../ResetPassword/"));
const Profile = lazy(() => import("../../Profile"));
const TalentAdd = lazy(() => import("../../Talent/TalentAdd"));
const TalentList = lazy(() => import("../../Talent/TalentList"));
const TalentDetail = lazy(() => import("../../Talent//TalentDetail"));
const CompanyUserAdd = lazy(() => import("../../Company/CompanyUserAdd"));
const CompanyUserList = lazy(() => import("../../Company/CompanyUserList"));
const CompanyUserDetails = lazy(() => import("../../Company/CompanyUserDetails"));
const OpportunityList = lazy(() => import("../../Opportunity/OpportunityList"));
const HiredTalentList = lazy(() => import("../../Opportunity/HiredTalentList"));
const HeadhunterAdd = lazy(() => import("../../Headhunter/HeadhunterAdd"));
const HeadhunterList = lazy(() => import("../../Headhunter/HeadhunterList"));
const HeadhunterDetail = lazy(() => import("../../Headhunter/HeadhunterDetail"));
const CompanyHiredTalentList = lazy(()=> import('../../Company/HiredTalent/CompanyHiredTalentList'))
const Inquiry = lazy(() => import('../../Inquiry/InquiryList'));
const PageForm = lazy(() =>  import('../../CMS/PageForm'));
const PageList = lazy(() =>  import('../../CMS/PageList'));
const FormulaCreate = lazy(() =>  import('../../Formula/FormulaCreate'));
const FormulaList = lazy(() =>  import('../../Formula/FormulaList'));
const AppMain = () => {
  const [loading, setLoading] = React.useState(true);
  const { user: currentUser , isLoggedIn } = useSelector((state) => state.auth);
  const { keys } = useSelector((state) => state.session);

  
  // if (currentUser && (currentUser.roleId === 5 || currentUser.roleId === 2)) {
  //   isLoggedIn = true;
  // }
  const dispatch = useDispatch();
  useEffect(() => {
     const checkKeysAndSetLoginStatus = async () => {
      // console.log("MainLayout Data>>>>>>>>>>>>>>>" , keys)
       try {
         if (keys) {
           await decrypt(keys);
         }
        setLoading(false);
       } catch (error) {
         console.error("Error decrypting keys:", error);
        //  dispatch(clearSession());
         setLoading(false);
       }
     };
      // console.log("Login",isLoggedIn)
     checkKeysAndSetLoginStatus();
  }, [keys , dispatch]);

  if (loading) {
    return <div className="spinner-border text-success" role="status">
    <span className="sr-only">Loading...</span>
  </div>; 
  }

  return (
    <Routes>
      <Route element={<ProtectedRoutes isLoggedIn={isLoggedIn} />}>
        <Route path="dashboard" key="dashboard" element={<Dashboard />} />
        <Route path="profile" key="profile" element={<Profile />} />
        <Route path="talent/add" key="talent/add" element={<TalentAdd />} />
        <Route path="talent/list" key="talent/list" element={<TalentList currentUser={currentUser} />} />
        <Route path="talent/detail/:id" key="talent/detail" element={<TalentDetail currentUser={currentUser} />} />
        <Route path="company-user/add" key="company-user/add" element={<CompanyUserAdd />} />
        <Route path="company-user/list" key="company-user/list" element={<CompanyUserList currentUser={currentUser} />} />
        <Route path="company-user/detail/:id" key="company-user/detail" element={<CompanyUserDetails currentUser={currentUser} />} />
        <Route path="company-user/opportunities-list" key="company-user/opportunities-list" element={<OpportunityList currentUser={currentUser} />} />
        <Route path="company-user/hired-talent-list/:id" key="company-user/hired-talent-list" element={<HiredTalentList currentUser={currentUser} />} />
        <Route path="headhunter/add" key="headhunter/add" element={<HeadhunterAdd />} />
        <Route path="headhunter/list" key="headhunter/list" element={<HeadhunterList currentUser={currentUser} />} />
        <Route path="headhunter/detail/:id" key="headhunter/detail" element={<HeadhunterDetail currentUser={currentUser} />} />

        <Route path="formula/create" component={FormulaCreate} />

        <Route path="company-user/plan-detail" key="plan/detail" element={<PlansListDetails currentUser={currentUser} />} />
              
        <Route path="subscription/add" key="subscription/add" element={<SubscriptionAdd />} />
        <Route path="subscription/list" key="subscription/list" element={<SubscriptionList currentUser={currentUser} />} />
        <Route path="subscription/edit/:id" key="subscription/edit" element={<SubscriptionEdit currentUser={currentUser} />} />
        <Route path="hired-talent/list" key="hired-talent/list" element={<CompanyHiredTalentList currentUser={currentUser} />} />
        <Route path="inquiry" key="inquiry" element={<Inquiry currentUser={currentUser} />} />
      
        <Route path="cms/add" key="inquiry" element={<PageForm currentUser={currentUser} />} />
        <Route path="cms/list" key="hired-talent/list" element={<PageList currentUser={currentUser} />} />
        <Route path="formula/add" key="hired-talent/list" element={<FormulaCreate currentUser={currentUser} />} />
        <Route path="formula/list" key="hired-talent/list" element={<FormulaList currentUser={currentUser} />} />
      
      </Route>
      
      <Route element={<PublicRoutes isLoggedIn={isLoggedIn} />}>
        <Route path="login" key="login" element={<Login />} />
        <Route
          path="forgot-password"
          key="forgot-password"
          exact
          element={<ForgotPassword />}
        />
        <Route
          path="reset-password"
          key="reset-password"
          element={<ResetPassword />}
        />
        <Route path="/" key="/" element={<Login />} />
            
      
      </Route>
        <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppMain;
