// utils/cookieStorage.ts
import Cookies from "js-cookie";

const cookieStorage = {
  getItem: async (key) => {
    return Promise.resolve(Cookies.get(key) ?? null);
  },
  setItem: async (key, value) => {
    Cookies.set(key, value, { expires: 7 }); // Cookie expires in 7 days
    return Promise.resolve();
  }
}

export default cookieStorage; 