import {
    RETRIEVE_USERS_RISK,
  } from "../actions/types";
  
  const initialState = { usersRisk : [], totalUserRiskCount : 0 };

const userRiskReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {

    case RETRIEVE_USERS_RISK:
      return {
        usersRisk: payload.rows,
        totalUserRiskCount: payload.count,
      };


    default:
      return state;
  }
};

export default userRiskReducer;