const RSA_KEY = `-----BEGIN RSA PRIVATE KEY-----
MIIJKQIBAAKCAgEAp8cFSaf3rRh/w0/GM0j27JdP1JfNPZDPmM91bhI++UrplK36
Nudpy2zRzDK5BuBz95Phx78QbwjnKoOdsH0ZmbXdlPZbsY++yVYFouTgtyXXjSOz
KaWbio6mlJRp7dhYm8Q22Rn2S7InOpErlNCXYo0zjvcPJfzxS+6LvpmHT7Crzbhc
fBfJgUcWS7A6N4DKnNnjPXUQkNg/1A20gI6EjMWo/QKh3xifJO+8xkdWS94ekOBF
Fin+buK/LjcLZl9AFzoctihgWVQn07kTDGmWLTRuuQjiuFgb2PQc1fmpqjBiZvbs
VPxWKlOK3PTYcOpfBbzuGZ45nLjOkHsQmiDNOImcoL/0gQYKUbl+ig3yN5fAAKQV
yuIRyQXt9r5EuwiuZbnxG/efxPpyKg4lw32K4+YsRMCe5sddfkwGm8TQn4MsIy0P
Mwr+TcuhxA86KesxUJnawwb+lRMy8ltWL/1/RGkvypQOvUSexMrlnHRgngtDc8ZR
Eg7amlOvxzfRRiBN5T5I1chxHxqMasF8IXu+OXDAbJ6rRVmfhSdnbsa1g1NTwxo4
82yriZQ7oFkCW0nkZ9NL2pk9GqpHF+Zlq015KyCnjwK8r+yJWE50ebgxoibZmByP
PGgJYVLslXR/k3aCuY9504JfaGVyPA8k2SwMFys8bHUe0nCMSCIe3O1BpIkCAwEA
AQKCAgEAltMoIr3XvojumgLF92aZa7jesAvDGpxqs0GUnF3UtMCuWNVJwT+pcJEj
zQCwKsluFtufNbTCLj7/qM1m3bioYfEHABmz9lx1s4McUAKXLK3dJ2k3b7ILsLRq
ddkxnPpHNchfUi2vpv7BmznTxtT5skOjXNsmiAzqUktg3vgflvkIiUg6A+hSYmAo
X+RNgYXLHtQVHzN0r/Fm99dWM9dquy4Zq1FJeUgmEMtflZOdtVg5fB3QnYskykQW
f5JGMkJgWEEKfIqxirxHjWKsCBWHRp4DB15Ail3SsudIYOcuqesTGuLRM9kgEK7H
f7iwNlnLEFp2ZRtDSWstbt7i3tur/7mNLC6RkutJbB0vtpiJHZcZlHdFRtjv5fj7
zbUx9KdIjg2o0L7XKPUdWJJ5+cXV1+R5gLWIW0NH9FcRfd4CrrsXXGDoPNgOeRIw
f5AMw+mqMp4rfAbCoU+AJ9Y1IqfClQd6Thj9IP1jd82kBUJBacPy3konwUqAvHgX
LUuBCvZ3uUqFKnv/lD2OLfd/+vFyCJmctccq95yknCeqQ/qat5d15VlPJVLMLCeq
ZFMipfb2CIiVAIHUQL1fGoqOCQB3El9uCqsG4aO8AeASF8SVEI1I7xwOimGvIi0c
XASsEfnC1zGvho+ZbBcz06OkOWUaa//7neIcorbNAW357HmpNDECggEBANYc5/Ss
yttTA1CSelxlSBq+WiCMGQBr8PcJMdjY7cfY/8ho2KavEPqV9hbpmj4ytSG9QMYj
JsAsbXY7AIoqFq4E6W9+mUUl2d07ZzMMxeXqQxBcXMHRYrjyszfC049GxUFsvQ3u
LmBjLJyMsuZ6hxwWTi53U3xyQb86Bww4PfeogR608YWaGqVmjSPUzuKGbNMVvOMr
oV6YQ/vmSTGqTq6T6cmyXTVK8XyOaFQosl4Lam66vH357fjnluTbXMQOmgOVotnx
9w3YRLmS32idwCuNfrOvzphH3p0tH9g6qausoceAv/B/5pdugHmlO52OKZ9Sv80G
U3pghlzIfJeehP0CggEBAMiZkG2Jctr1JoAgX+fZlcVpFiTN0sqX050O0/y54dF5
CMWX/+oMtYcc/NIwfjlNSTcmlFyuZ0ujEbeVZlUQGDjoFTEFBhtusYSRqOmYD+8X
GzJqJTz92cG+cssr+awdAVjkYxSbUfBFfxY4DLgZ6S6ATPyuhlJVIItfCm6QsPpa
a1kyrycfD31WPS++yPhC9kZ4Kr4QOr6tIXl7fagQcNiblrJdjCy5l/Vfdw/RV7Xb
pIIafxWY5SMUfykoXe8kdzsp0icbnnYPTgZV8EzfDguTp66AoZfD5HNnAu0hItQn
Pq6LujJBjfFQ0WvPknY3QIjZ3xJBpE1/nWEoAra2GX0CggEAQsTO3LEJrW1sqjKT
b76xxo03o9Csd0Ix6Yd3gOzc8aZM1V1Eum37eHlNPYXpcJ31eCkBSwhpLCJunCD4
IR3Q5sFnXjp3Z2XPU7sTiorgJ7tu4Go9Dr/R7mTY2Hmr6ZEjMCzNo8tzsOEcZ1FE
9LSe73ZRORJC+ZrTv+NDkV70jAeDBGVer9AHoS6istrJDUDoLMcpmRng/5OyF5qP
lqpKUQyVKi2x/U16kuIBzMTIIqWishcPcqV+vVDS8Fkry4PWKWn/V8Gpzipz+/6+
JmyTIcHFEhmv7jLzS8ZnmNFqWbvC02jl8gSgjvaJn+WT+qT7e9CdOT1QyOfS3poy
qEFprQKCAQAV0iaU08PLr6ktfNgR52hUXhcLj9wjL5rUVd2XezzmRW0/2UX8Vr+U
YAHcd2vzncWeGzM+VbZk0utFdhn4nNSVtCFCANLmPwdObW3bYzAwiOnrdQJx/Qcn
/SiNk04Ie+s+mTFx92dVZKnrZ9NyX1cvOMOnJPD1mc79eiwvVMv7n8RXopzM+6L6
tlvXisBfySDzD3VO0YMiZMDkbxt/IbK0zU/6PRTXdKTkCytvbA1HegoOIQW7+rLK
OxuOBZcaQXQ62lTC7k0xeH67T7PhGM51Ue2GnPV3Bdce+pCl7NnWDCLUZ9ysRNdE
7c6qFJ1RALeja1fJq5xM1dnvzKNm7q4dAoIBAQCtdGOOnXN5TvBs7xBrMKydD9It
iE5KyHbKnhxg3InBSuUz4RsUoLDtY0m6mXIN2AZ2aaIUhIdepH0FYB44Ai1xL7rc
F9r1yWxnzeNzaOzLsddbJCITnaQ9bmGhzoerfMcvugSFSGaiZqlNqLNj2pNlioaM
eEReLKkK0pV/8Uf1xYfA38eA1LB3CRngcF0XFoAqoFzImt767tb6lw9UMPE0fR9B
DGHJS1wusJjc7hsLuMJFnenkZod0vR5NwJjTkiR7X0NobyiOimJQcXRWwT8JGbGT
9J0vf1aWvydChOHUwH0jkJXYhCj7ZjVyLMHhHYgPlh4wQOVOvQRM3giHsjEP
-----END RSA PRIVATE KEY-----`;
export default RSA_KEY;
