import { GET_COMPANY_DETAIL ,RETRIEVE_CURRENT_COMPANY } from "../actions/types";

const initialData = { 
  company: {}, // Initialize with an empty object or array, depending on expected data structure
  companyDetails: {}
};

const companyReducer = (state = initialData, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_COMPANY_DETAIL:
      // console.log(payload)
      return {
        ...state,
        company: payload,
      };
    case RETRIEVE_CURRENT_COMPANY:
      // console.log(payload)
      return {
        ...state , 
        companyDetails: payload
      };
    default:
      return state;
  }
};

export default companyReducer;
