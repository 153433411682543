export const AdminNav = [
  {
    icon: "pe-7s-graph2",
    label: "Dashboard",
    to: "/dashboard",
  },
  
  // {
  //   icon: "pe-7s-user",
  //   label: "Advisors",
  //   content: [
  //     {
  //       label: "Add",
  //       to: "/user/admin/add",
  //     },
  //     {
  //       label: "List",
  //       to: "/user/admin",
  //     },
  //   ],
  // },
  // {
  //   icon: "pe-7s-user",
  //   label: "Client",
  //   content: [
  //     {
  //       label: "Add",
  //       to: "/user/users/add",
  //     },
  //     {
  //       label: "List",
  //       to: "/user/all",
  //     },
  //   ],
  // },

  // {
  //   icon: "pe-7s-config",
  //   label: "Settings",
  //   to: "/settings/general",
  //   content: [
  //     {
  //       label: "Manage Roles",
  //       to: "/settings/general",
  //     },
  //     {
  //       label: "General Settings",
  //       to: "/settings/config",
  //     },
  //     {
  //       label: "Home CMS",
  //       to: "/settings/homeCms",
  //     },
  //     {
  //       label: "About CMS",
  //       to: "/settings/aboutCms",
  //     },
  //     {
  //       label: "How It works CMS",
  //       to: "/settings/howItWorks",
  //     },
  //   ],
  // },

  // {
  //   icon: "pe-7s-user",
  //   label: "Requests",
  //   to : "/user/query"
  // },
  
];

// export const Advisor = [
//   {
//     icon: "pe-7s-graph2",
//     label: "Dashboard",
//     to: "/dashboard",
//   },

//   {
//     icon: "pe-7s-user",
//     label: "Talent",
//     content: [
//       {
//         label: "Add",
//         to: "/talent/add",
//       },
//       {
//         label: "List",
//         to: "/talent/list",
//       },
//     ],
//   },
//   {
//     icon: "pe-7s-user",
//     label: "Company",
//     content: [
//       {
//         label: "Add",
//         to: "/company-user/add",
//       },
//       {
//         label: "List",
//         to: "/company-user/list",
//       },
//     ],
//   },
//   {
//     icon: "pe-7s-user",
//     label: "Headhunter",
//     content: [
//       {
//         label: "Add",
//         to: "/headhunter/add",
//       },
//       {
//         label: "List",
//         to: "/headhunter/list",
//       },
//     ],
//   },
  
// ];

export const Advisor = [
  {
    icon: "pe-7s-graph2 pe", // Icon for Dashboard
    label: "Dashboard",
    to: "/dashboard",
  },
  {
    icon: "pe-7s-user pe", // Icon for Talent
    label: "Talent",
    to: "/talent/list",
    // submenu: [
    //   {
    //     label: "Add",
    //     to: "/talent/add",
    //     icon: "pe-7s-plus", // Example icon for Add (replace with appropriate icon class)
    //   },
    //   {
    //     label: "List",
    //     to: "/talent/list",
    //     icon: "pe-7s-menu", // Example icon for List (replace with appropriate icon class)
    //   },
    // ],
  },
  {
    icon: "pe-7s-users pe", // Icon for Company
    label: "Company",
    to: "/company-user/list",
    // submenu: [
    //   {
    //     label: "Add",
    //     to: "/company-user/add",
    //     icon: "pe-7s-plus", // Example icon for Add (replace with appropriate icon class)
    //   },
    //   {
    //     label: "List",
    //     to: "/company-user/list",
    //     icon: "pe-7s-menu", // Example icon for List (replace with appropriate icon class)
    //   },
    // ],
  },
  {
    icon: "pe-7s-id", // Icon for Headhunter
    label: "Headhunter",
    submenu: [
      {
        label: "Add",
        to: "/headhunter/add",
        icon: "pe-7s-plus", // Example icon for Add (replace with appropriate icon class)
      },
      {
        label: "List",
        to: "/headhunter/list",
        icon: "pe-7s-menu", // Example icon for List (replace with appropriate icon class)
      },
    ],
  },
  {
    icon: "pe-7s-id", // Icon for Headhunter
    label: "Subscription",
    submenu: [
      {
        label: "Add",
        to: "/subscription/add",
        icon: "pe-7s-plus", // Example icon for Add (replace with appropriate icon class)
      },
      {
        label: "List",
        to: "/subscription/list",
        icon: "pe-7s-menu", // Example icon for List (replace with appropriate icon class)
      },
    ],
  },
  {
    icon: "pe-7s-id", // Icon for Headhunter
    label: "CMS",
    submenu: [
      {
        label: "Add",
        to: "/cms/add",
        icon: "pe-7s-plus", // Example icon for Add (replace with appropriate icon class)
      },
      {
        label: "List",
        to: "/cms/list",
        icon: "pe-7s-menu", // Example icon for List (replace with appropriate icon class)
      },
    ],
  },
  {
    icon: "pe-7s-id", // Icon for Headhunter
    label: "Formula",
    submenu: [
      {
        label: "Create",
        to: "/formula/add",
        icon: "pe-7s-plus", // Example icon for Add (replace with appropriate icon class)
      },
      {
        label: "List",
        to: "/formula/list",
        icon: "pe-7s-menu", // Example icon for List (replace with appropriate icon class)
      },
    ],
  },
  {
    icon: "pe-7s-graph2 pe", // Icon for Dashboard
    label: "Inquiry",
    to: "/inquiry",
  },
];
