import { GET_ALL_SETTINGS, UPDATE_SETTINGS } from "../actions/types";

const initialState = { settings: [], totalCount: 0 };

const settingReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_SETTINGS:
      return {
        ...state,
        settings: payload.settings,
        totalCount: payload.totalCount,
      };

    case UPDATE_SETTINGS:
      return {
        ...state,
        settings: { ...state.settings, ...payload },
      };

    default:
      return state;
  }
};

export default settingReducer;
