"use strict";
import axios from "axios";
import { logout } from "../redux/actions/auth";
import { store } from "../config/configureStore";
import { decrypt } from "../utils/decrypt";
import { useDispatch } from "react-redux";
import { setSession } from "../redux/actions/session";
import { LOGIN_SUCCESS } from "../redux/actions/types";

// debugger
// const dispatch = useDispatch();
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
});

export const getRefreshToken = async (userId) => {
  try {
    const res = await api.get(
      process.env.REACT_APP_API_URL + `user/renewSession/${userId}`,
      { withCredentials: true }
    );
    if (res) {
      if (res?.data?.response) {
        const keys = {
          encryptedAESKey: res?.data?.response?.encryptedAESKey,
          userData: res?.data?.response?.userData,
          iv: res?.data?.response?.iv,
        };

        const v1 = await decrypt(keys);
        store.dispatch({
          type: LOGIN_SUCCESS,
          payload: { user: res?.data?.response },
        });
        store.dispatch(setSession(keys));
        return res
      }
    } else {
      store.dispatch(logout());
    }
  } catch (error) {
    store.dispatch(logout());
  }
};

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    // Handle 401 errors for JWT expiration
    const originalRequest = error.config;
    // Initialize retry count if not already set
    if (!originalRequest._retryCount) {
      originalRequest._retryCount = 0;
    }
    if (error.response && (error.response.status === 401 || error.response.status === 403) && originalRequest._retryCount < 3) {
      originalRequest._retryCount += 1
      try {
        const state = store.getState();
        const userId = state.auth?.user?.publicId;

        if (!userId) {
          store.dispatch(logout());
          return Promise.reject(error);
        }

        const res = await getRefreshToken(userId);
        if (res && res.status === 200) {
          return api(originalRequest);
        } else {
          store.dispatch(logout());
        }
      } catch (err) {
        // console.error("Error in refresh token logic:", err);
        store.dispatch(logout());
      }
    }
    return Promise.reject(error);
  }
);

export default api;
