import { useEffect, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
} from "reactstrap";
import PageContainer from "../Layout/PageContainer";
import subscriptionService from "../../redux/services/subscription.service";
import { useDispatch, useSelector } from "react-redux";
import {
  getFeatures,
  retrieveSubscriptionById,
} from "../../redux/actions/subscription";
import { toast, Slide } from "react-toastify";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function SubscriptionEdit() {
  const id = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    publicId: id.id,
    planFor: "",
    title: "",
    description: "",
    isPopular: false,
    frequency: "",
    price: "",
    featureIds: [],
    accesses: {},
  });

  const [userType, setUserType] = useState({ planFor: "" });
  const [filteredFeatures, setFilteredFeatures] = useState([]);
  const [loading, setLoading] = useState(false);

  const { features } = useSelector((state) => state?.subscriptionReducer);
  const [benefitOptions, setBenefitOptions] = useState({});
  const res = useSelector(
    (state) => state?.subscriptionReducer?.subscriptionList
  );

  useEffect(() => {
    const publicId = id.id;
    dispatch(getFeatures(1));
    dispatch(retrieveSubscriptionById(publicId));
  }, [dispatch, id]);

  useEffect(() => {
    if (res && res.length > 0) {
      const currentPlan = res[0];
      setUserType({ planFor: currentPlan.planFor?.toString() || "" });
      setFormData({
        ...formData,
        planFor: currentPlan.planFor?.toString() || "",
        title: currentPlan.title || "",
        description: currentPlan.description || "",
        isPopular: currentPlan.isPopular ? true : false,
        frequency: currentPlan.frequency?.toString() || "",
        price: currentPlan.price?.toString() || "",
        featureIds: currentPlan.featureIds || [],
      });
    }
  }, [res]);

  // Filtering features whenever `planFor` changes
  useEffect(() => {
    if (features && userType.planFor !== "") {
      const filtered = features.filter(
        (feature) => feature.featureFor?.toString() === userType.planFor
      );
      setFilteredFeatures(filtered);
    } else {
      setFilteredFeatures([]); // Reset filtered features when planFor is empty
    }
  }, [userType.planFor, features]);

  // Handle checkbox change
  const handleCheckboxChange = (publicId) => {
    let updatedBenefits = [...formData.featureIds];
    let updatedOptions = { ...benefitOptions };

    if (updatedBenefits.includes(publicId)) {
      updatedBenefits = updatedBenefits.filter((id) => id !== publicId);
      delete updatedOptions[publicId]; // remove from benefitOptions
    } else {
      updatedBenefits.push(publicId);
      updatedOptions[publicId] = { type: "one", number: 1 };
    }

    const updatedAccesses = getAccesses(updatedOptions); // calculate after deletion

    setFormData((prev) => ({
      ...prev,
      featureIds: updatedBenefits,
      accesses: updatedAccesses,
    }));
    setBenefitOptions(updatedOptions);
  };

  const handleOptionChange = (publicId, option) => {
    let updatedOptions = { ...benefitOptions };
    if (option === "one") {
      updatedOptions[publicId] = { type: "one", number: 1 };
    } else if (option === "infinity") {
      updatedOptions[publicId] = { type: "infinity", number: 1 };
    } else if (option === "number") {
      updatedOptions[publicId] = {
        ...updatedOptions[publicId],
        type: "number",
        number: updatedOptions[publicId]?.number || 1,
      };
    }
    setFormData((prev) => ({
      ...prev,
      accesses: getAccesses(updatedOptions),
    }));
    setBenefitOptions(updatedOptions);
  };

  const handleNumberChange = (publicId, value) => {
    let num = parseInt(value);
    if (isNaN(num) || num < 1) num = 1;
    let updatedOptions = { ...benefitOptions };
    updatedOptions[publicId].number = num;
    setFormData((prev) => ({
      ...prev,
      accesses: getAccesses(updatedOptions),
    }));
    setBenefitOptions(updatedOptions);
  };

const getAccesses = (options) => {
  const acc = {};
  Object.entries(options).forEach(([key, value]) => {
    if (value.type === "infinity") {
      acc[key] = "Infinity";
    } else if (value.type === "one") {
      acc[key] = 1;
    } else if (value.type === "number") {
      acc[key] = value.number;
    }
  });
  return acc;
};


  const handleFrom = (e) => {
    formData.planFor = e.target.value;
    setUserType({ ...userType, planFor: e.target.value });
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormData({
      ...formData,
      [name]: type === "checkbox" ? (checked ? 1 : 0) : value,
    });
  };

  //#region Update Form
  const handleUpdate = (e) => {
    e.preventDefault();
    setLoading(true);
    subscriptionService
      .updateSubscription(formData)
      .then(
        (res) => {
          setLoading(false);
          if (res.status === 200) {
            toast(res.data.message, {
              transition: Slide,
              closeButton: true,
              autoClose: 3000,
              position: "top-right",
              type: "success", // info/success/warning/error
            });
          } else {
            toast(res.data.message, {
              transition: Slide,
              closeButton: true,
              autoClose: 3000,
              position: "top-right",
              type: "error", // info/success/warning/error
            });
          }
        },
        (err) => {
          toast(err.response.data.message, {
            transition: Slide,
            closeButton: true,
            autoClose: 3000,
            position: "top-right",
            type: "error", // info/success/warning/error
          });
          console.log(err);
        }
      )
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  // #endregion

  return (
    <PageContainer
      pageTitleIcon="pe-7s-shuffle icon-gradient bg-plum-plate"
      pageHeading="Edit Subscription"
      pageSubTitle="Edit Subscription for Talent & Company"
    >
      <Row>
        <Col md="12">
          <Card className="main-card mb-3">
            <Form onSubmit={handleUpdate}>
              <CardBody>
                <Row>
                  {/* Form Fields */}
                  <Col md="4">
                    <FormGroup>
                      <Label for="price">Select Plan Type</Label>
                      <Input
                        type="select"
                        name="planFor"
                        id="planFor"
                        value={userType.planFor}
                        onChange={handleFrom}
                      >
                        <option value="">Select Plan Type</option>
                        <option value="0">Talent Plan</option>
                        <option value="1">Company Plan</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Label for="frequency">Select Billing Cycle</Label>
                      <Input
                        type="select"
                        name="frequency"
                        id="frequency"
                        value={formData.frequency}
                        onChange={handleChange}
                      >
                        <option value="0">Select Billing Cycle</option>
                        <option value="1">Monthly Plan</option>
                        <option value="2">Yearly Plan</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Label for="price">Price</Label>
                      <Input
                        type="number"
                        name="price"
                        id="price"
                        placeholder="Ex: 1000"
                        value={formData.price}
                        onChange={handleChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Label for="title">Plan Title</Label>
                      <Input
                        type="text"
                        name="title"
                        id="title"
                        placeholder="Ex: Demo Plan"
                        value={formData.title}
                        onChange={handleChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Label for="isPopular">Popular Plan</Label>
                      <Input
                        type="select"
                        name="isPopular"
                        id="isPopular"
                        onChange={handleChange}
                      >
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Label for="plantitle">Description</Label>
                      <Input
                        type="textarea"
                        name="description"
                        id="description"
                        placeholder="Ex: Demo Plan"
                        value={formData.description}
                        onChange={handleChange}
                      />
                    </FormGroup>
                  </Col>

                  {/* Feature Options */}
                  <Col md="12">
                    {filteredFeatures.length > 0 ? (
                      filteredFeatures.map((feature, i) => (
                        <FormGroup key={i}>
                          <Label for={`benefits_${i}`}>{feature.title}</Label>
                          <Input
                            type="checkbox"
                            name={`benefits_${i}`}
                            id={`benefits_${i}`}
                            className="mx-2"
                            onChange={() =>
                              handleCheckboxChange(feature.publicId)
                            }
                            checked={formData.featureIds.includes(
                              feature.publicId
                            )}
                          />
                          {formData.featureIds.includes(feature.publicId) && (
                            <div className="ml-4 mt-2 d-flex flex-column gap-2">
                              {/* Option - One */}
                              <div className="d-flex align-items-center gap-2">
                                <Input
                                  type="checkbox"
                                  checked={
                                    benefitOptions[feature.publicId]?.type ===
                                    "one"
                                  }
                                  onChange={() =>
                                    handleOptionChange(feature.publicId, "one")
                                  }
                                />
                                <span>One</span>
                              </div>

                              {/* Option - Number */}
                              <div className="d-flex flex-column">
                                <div className="d-flex align-items-center gap-2">
                                  <Input
                                    type="checkbox"
                                    checked={
                                      benefitOptions[feature.publicId]?.type ===
                                      "number"
                                    }
                                    onChange={() =>
                                      handleOptionChange(
                                        feature.publicId,
                                        "number"
                                      )
                                    }
                                  />
                                  <span>Number</span>
                                </div>

                                {/* Default Number Input */}
                                {benefitOptions[feature.publicId]?.type ===
                                  "number" && (
                                  <div className="d-flex align-items-center mt-1 ml-4">
                                    <Input
                                      type="number"
                                      value={
                                        benefitOptions[feature.publicId]?.number
                                      }
                                      onChange={(e) =>
                                        handleNumberChange(
                                          feature.publicId,
                                          e.target.value
                                        )
                                      }
                                      style={{ width: "100px" }}
                                      min={1}
                                    />
                                  </div>
                                )}
                              </div>

                              {/* Option - Infinity */}
                              <div className="d-flex align-items-center gap-2">
                                <Input
                                  type="checkbox"
                                  checked={
                                    benefitOptions[feature.publicId]?.type ===
                                    "infinity"
                                  }
                                  onChange={() =>
                                    handleOptionChange(
                                      feature.publicId,
                                      "infinity"
                                    )
                                  }
                                />
                                <span>Infinity</span>
                              </div>
                            </div>
                          )}
                        </FormGroup>
                      ))
                    ) : (
                      <span>
                        Please Select plan type to see available features
                      </span>
                    )}
                  </Col>
                </Row>
              </CardBody>
              <CardFooter className="d-block">
                <Button
                  type="button"
                  color="link"
                  onClick={() => navigate("/subscription/list")}
                >
                  Cancel
                </Button>
                <Button color="primary" size="lg" disabled={loading}>
                  {loading ? (
                    <span className="spinner-border spinner-border-sm"></span>
                  ) : (
                    "Submit"
                  )}
                </Button>
              </CardFooter>
            </Form>
          </Card>
        </Col>
      </Row>
    </PageContainer>
  );
}

export default SubscriptionEdit;
