import React, { useState, useEffect, useCallback } from "react";
import { Button, Modal } from "react-bootstrap";
import { Form, Label, Input, Table, Card, CardBody } from "reactstrap";
import {
  GetFormulaSearch,
  verifyFormula,
} from "../../redux/services/formula.service";
import debounceFunction from "../../helpers/Debounce";
import RuleTable from "./guideTable";
import { toast } from "react-toastify";

const FormulaModal = ({ show, handleClose, handleadd, value }) => {
  const [equationformula, setequationformula] = useState(value || "");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [selectedData, setSelectedData] = useState(null);
  const [isFormulaValid, setIsFormulaValid] = useState(false);
  const [showRules, setShowRules] = useState(true);
  const [showSearchModal, setShowSearchModal] = useState(false);

  useEffect(() => {
    if (show) {
      setequationformula(value || "");
    }
  }, [show, value]);

  useEffect(() => {
  }, [show, showSearchModal]);

useEffect(() => {
  if (showSearchModal) {
    console.log("Fetching default 20 search results...");
    GetFormulaSearch("")
      .then((response) => {
        if (response && response.result) {
          setSearchResults(response.result);
        }
      })
      .catch((error) => {
        console.error("Error fetching default search results:", error);
      });
  }
}, [showSearchModal]);

  const cleanEquation = (formula) => {
    return formula
      .replace(/\s*\n\s*/g, "")
      .replace(/\s+/g, " ")
      .replace(/\s*([(){}=><!&|])\s*/g, "$1")
      .trim();
  };

  const getSearchResults = useCallback(
    debounceFunction((keyword) => {
      if (keyword.trim() !== "") {
        GetFormulaSearch(keyword)
          .then((response) => {
            if (response && response.result) {
              setSearchResults(response.result);
            }
          })
          .catch((error) => {
            console.error("error");
          });
      } else {
        setSearchResults([]);
      }
    }, 1000),
    []
  );

  const handleSearch = (e) => {
    const keyword = e.target.value;
    setSearchTerm(keyword);
    getSearchResults(keyword);
  };

  const handleDataSelect = (data) => {
    setSelectedData(data);
    setequationformula((prev) =>
      prev ? `${prev} ${data.formulaKey}` : data.formulaKey
    );
    setShowSearchModal(false);
  };

  const handleVerifyFormula = () => {
    const cleanedFormula = cleanEquation(equationformula);
    verifyFormula({ equation: cleanedFormula })
      .then((response) => {
        if (response?.status === 200) {
          toast.success("Formula is valid");
          setIsFormulaValid(true);
        } else {
          toast.error(
            response?.data?.message ||
              "Please check the column type and their data type."
          );
          setIsFormulaValid(false);
        }
      })
      .catch((error) => {
        toast.error("An error occurred while verifying the formula.");
        setIsFormulaValid(false);
      });
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} className="equation-modal twocol">
        <Modal.Header closeButton>
          <Modal.Title className="text-primary">Equation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div>
              {/* Conditionally Render RuleTable and Rules Card */}
              {showRules && (
                <>
                  <RuleTable />

                  {/* Rules Card */}
                  <Card className="m-3 shadow-none border border-gray-300 w-full">
                    <CardBody>
                      <h5 className="text-primary">Formula Rules</h5>
                      <ol>
                        <li>
                          Every operation must be in parentheses{" "}
                          <strong>[Ex: (oluo2&gt;10)]</strong>
                        </li>
                        <li>
                          Every pair of parentheses should have 2 operands and 1
                          operator.
                        </li>
                        <li>
                          Aggregators like <strong>MAX, MIN</strong> will have a
                          single column as a parameter.
                        </li>
                        <li>
                          The values provided for comparison should always be
                          enclosed in quotes.
                        </li>
                        <li>
                          Example formula:{" "}
                          <strong>
                            ((oluo12="India") AND (oluo8="High School"))
                          </strong>
                        </li>
                        <li>
                          <p>Only one pair of if-else condition is allowed -</p>
                          Example if-else formula:
                          <strong>
                            <pre className="whitespace-pre-wrap break-words">
                              <code>
                                if(((oluo155='India') AND (oluo167=200000))){" "}
                                {"{"} {"\n"}
                                &nbsp;&nbsp;((oluo160='Male') AND (oluo165='A')){" "}
                                {"\n"}
                                {"}"} else {"{"} {"\n"}
                                &nbsp;&nbsp;((oluo155='UAE') AND (oluo165='A')){" "}
                                {"\n"}
                                {"}"}
                              </code>
                            </pre>
                          </strong>
                        </li>
                      </ol>
                    </CardBody>
                  </Card>
                </>
              )}
              <div className="d-flex justify-content-end">
                <Button
                  variant="primary"
                  type="button"
                  className="btn btn-primary rounded-pill"
                  onClick={() => setShowRules(!showRules)}
                >
                  {showRules ? "Hide" : "Show Rules"}
                </Button>
              </div>
            </div>

            <Label className="text-primary mt-4 px-3">Equation</Label>
            <textarea
              className="form-control"
              style={{ width: "95%", margin: "auto" }}
              placeholder="Build your equation here"
              value={equationformula}
              onChange={(e) => setequationformula(e.target.value)}
            ></textarea>
            <div className="d-flex justify-content-end">
              <Button
                variant="primary"
                type="button"
                className="btn btn-primary rounded-pill mt-3"
                onClick={() => {
                  setShowSearchModal(true);
                }}
              >
                Search
              </Button>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            type="button"
            className="btn btn-primary rounded-pill"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            type="button"
            disabled={!equationformula}
            className="btn btn-primary rounded-pill"
            onClick={handleVerifyFormula}
          >
            Verify
          </Button>
          <Button
            variant="primary"
            type="button"
            disabled={!equationformula || !isFormulaValid}
            className="btn btn-primary btn-sm rounded-pill"
            onClick={() => {
              handleadd(equationformula);
              // setequationformula("");
            }}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      {/* ✅ Separate Search Modal */}
      <Modal
        show={showSearchModal}
        onHide={() => setShowSearchModal(false)}
        size="lg"
        className="search-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Search</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Label>Search</Label>
          <Input
            type="text"
            placeholder="Enter sub-categories"
            value={searchTerm}
            onChange={handleSearch}
            onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
          />

          {searchResults.length > 0 && (
            <div
              className="rounded-3 formula-page mt-3"
              style={{
                maxHeight: "280px",
                overflowY: "auto",
                border: "1px solid #dee2e6",
              }}
            >
              <Table className="table bg-white">
                <thead>
                  <tr style={{ fontSize: "13px", padding: "4px" }}>
                    <th style={{ padding: "6px" }}>Key</th>
                    <th style={{ padding: "6px" }}>Category</th>
                    <th style={{ padding: "6px" }}>Subcategory</th>
                    <th style={{ padding: "6px" }}>Field Name</th>
                    <th style={{ padding: "6px" }}>User Type</th>
                    <th style={{ padding: "6px" }}>Data Type</th>
                  </tr>
                </thead>
                <tbody>
                  {searchResults.map((result, index) => (
                    <tr
                      key={result.id || index} // Ensure key is valid
                      style={{
                        fontSize: "13px",
                        padding: "4px",
                        cursor: "pointer",
                        backgroundColor:
                          selectedData?.id === result.id ? "#e9ecef" : "white",
                      }}
                      onClick={() => handleDataSelect(result)}
                    >
                      <td style={{ padding: "6px" }}>{result.formulaKey}</td>
                      <td style={{ padding: "6px" }}>{result.category}</td>
                      <td style={{ padding: "6px" }}>{result.subcategory}</td>
                      <td style={{ padding: "6px", whiteSpace: "normal" }}>
                        {result.field}
                      </td>
                      <td style={{ padding: "6px", whiteSpace: "nowrap" }}>
                        {result.userType}
                      </td>
                      <td style={{ padding: "6px" }}>{result.type}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowSearchModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FormulaModal;
