import service from "../services/dashboard.service";

import { GET_CLIENT_ADVISOR, GET_REPORT } from "./types";

export const getDashboard = (id, step) => async (dispatch) => {
  try {
    const response = await service.getDashboard();

    if (response.status === 200) {
      dispatch({
        type: GET_CLIENT_ADVISOR,
        payload: response.data.data,
      });
    }
  } catch (err) {
    console.error("Error in getting dashboard data:", err);

    // Optionally dispatch a fallback action or handle silently
    dispatch({
      type: GET_CLIENT_ADVISOR,
      payload: null, // Fallback to null or an empty state
    });
  }
};

export const getReport = (data) => async (dispatch) => {
  try {
    const response = await service.getReport();
    console.log("getReport", response.data);

    if (response.status === 200) {
      dispatch({
        type: GET_REPORT,
        payload: response.data,
      });
      return response.data;
    }
  } catch (err) {
    console.error("Error in getting report data:", err);
  }
};
