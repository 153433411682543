import React from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import IconContainer from "../Common/IconContainer";
import * as Ionicons from "react-icons/io";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { getReport } from "../../redux/actions/dashboard";
import { FaDownload } from "react-icons/fa";
import { Button } from "react-bootstrap";

const PageContainer = (props) => {
  const location = useLocation();
  const pathName = location.pathname;
  const dispatch = useDispatch();

  const handleExportClick = async () => {
    try {
      const response = await dispatch(getReport());

      if (response && response.file) {
        const fileUrl = `https://${response.file}`;
        window.open(fileUrl, "_blank");

        toast.success(response.message || "File downloaded successfully!");
      } else {
        toast.error("Failed to download the report.");
      }
    } catch (error) {
      toast.error("An error occurred while fetching the report.");
    }
  };

  return (
    <TransitionGroup>
      <CSSTransition
        component="div"
        classNames="TabsAnimation"
        appear={true}
        timeout={1500}
        enter={false}
        exit={false}
      >
        <div style={{ overflow: "hidden" }}>
          <div className="app-page-title">
            <div className="page-title-wrapper d-flex justify-content-between align-items-center">
              <div className="page-title-heading d-flex align-items-center">
                <div className="page-title-icon">
                  <i className={props.pageTitleIcon} />
                </div>
                <div>
                  {props.pageHeading}
                  <div className="page-title-subheading">
                    {props.pageSubTitle}
                  </div>
                </div>
              </div>

              {pathName === "/dashboard" && (
                <>
                  <Button
                    variant="primary"
                    onClick={handleExportClick}
                    className="me-3 d-flex align-items-center"
                  >
                    <span>Download</span>
                    <FaDownload className="ms-2" />
                  </Button>
                </>
              )}
            </div>
          </div>
          {props.children}
        </div>
      </CSSTransition>
    </TransitionGroup>
  );
};

export default PageContainer;
